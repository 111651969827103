import React, { useEffect } from "react"

const TempHome = () => {
  useEffect(() => {
    if (window !== undefined) {
      window.location.replace(
        "https://www.instagram.com/neil_yoda_hill1/?hl=en"
      )
    }
  })
  return (
    <div>
      <h1></h1>
    </div>
  )
}

export default TempHome
